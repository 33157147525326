:root{
  --color-primary: #44486D;
  --color-secondary: #676B89;
}

*{
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background: radial-gradient(49.98% 52.41% at 44.18% 118.79%,rgba(252,120,136,.2) 0,rgba(252,82,102,0) 100%),radial-gradient(38.18% 50.85% at 43.11% -7.92%,rgba(50,138,245,.2) 0,rgba(50,138,245,0) 100%),radial-gradient(32.39% 65.68% at 101.26% 36.34%,rgba(98,57,198,.2) 0,rgba(98,57,198,0) 100%),radial-gradient(25.84% 56.13% at -7.92% 73.21%,rgba(192,16,239,.2) 0,rgba(192,16,239,0) 100%),#F9F8F9;
}

h1,h2,h3,h4,h5,h6{
  color: var(--color-primary);
}

h1{
  text-align: center;
}
#oninit-error{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-router-container{
  margin-left: 55px;
}

.tooltip-broker-message {
  border: 1px solid gray;
  border-radius: 2px;
  padding: 1px 5px 4px 5px;
  font-size: 15px;
  white-space: pre-wrap;
  color: gray !important;
  background-color: rgba(255, 255, 255, 0.9);
  max-width: unset !important;
  width: 100%;
}

.mat-drawer-inner-container::-webkit-scrollbar {
  overflow-x: hidden;
}

.graph::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.graph::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #f5f5f5;
}

.graph::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #555;
}


@media screen and (max-width: 570px) {
  .app-router-container{
    margin-left: 0px;
  }
}
